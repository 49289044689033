nav li{
    padding: 0 8px;
}
nav a{
    float: right;
}

.nav-item a:hover{
    color: rgb(27, 174, 156);
    text-decoration: underline ;
    text-underline-offset: 1em;
}
nav button{
    margin-bottom: 25px;
}

@media screen and (max-width:992px){
    .navbar{
        padding-top: 20px!important;
    }
    .navbar-nav a{
        display: block;
        width: 100%;
        text-align: right;
        padding-right: 15px;
    }
    .pintar{
        background-color: rgb(12,31,58);
    }
    .nav-item a:hover{
        text-decoration: none;
    }
    nav a:hover{
        background-color: rgb(10,25,47);
        border-radius: 4px;
    }
}