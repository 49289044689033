.contenedor-trabajos {
    padding: 0 3%;
}

.contenedor-trabajos li {
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-between;
    padding: 0.7rem;
}

.contenedor-trabajos ul {
    padding: 0;
    margin-bottom: 3px;
}

.contenedor-trabajos ul>li:nth-child(2n + 1) {
    background-color: rgb(12, 31, 58);
}

.contenedor-trabajos ul>li:nth-child(2n + 2) {
    background-color: rgb(10, 25, 47);
}

.loader {
    width: 50px;
    height: 50px;
    border: 10px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.carga {
    display: flex;
    padding: 15px 0;
}

.carga p {
    font-size: 40px;
    margin-left: 10px;
}
.contenedor-trabajos i{
    color: rgb(139, 146, 174);
    transition:0.4s;
}
.contenedor-trabajos i:hover{
    color: white;
    cursor: pointer;
}
.contenedor-imagenes-lenguajes{
    width: 100%!important;
}
.contenedor-imagenes-lenguajes {
    display: flex;
    padding: 4% 0;
    margin-bottom: 5%;
}

.contenedor-imagenes-lenguajes > div {
    flex: 1;
    margin: 5px;
    display: flex;
    align-items: center;
}

.contenedor-imagenes-lenguajes > div > img {
    width: 75%;
    height: auto;
    transition: width 0.4s;
    cursor: pointer;
    padding: 0 10%;
}

.contenedor-imagenes-lenguajes > div:hover > img {
    width: 70%;
}
.contenedor-imagenes-lenguajes > div:active > img {
    width: 65%;
}
@media (max-width: 768px) {
    .carga p {
        font-size: 25px;
    }
    .loader {
        width: 30px;
        height: 30px;
        border: 5px solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
    .contenedor-imagenes-lenguajes > div{
        width: 50%;
    }
    .contenedor-imagenes-lenguajes > div > img {
        width: 90%;
    }
    .contenedor-imagenes-lenguajes > div > img:hover {
        width: 85%;
    }
    .contenedor-imagenes-lenguajes > div:active > img {
        width: 80%;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}