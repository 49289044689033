.cajas{
    margin: 30px 0;
}
#mensaje{
    height: 150px;
}

@media (max-width: 1000px) {
    .formulario form{
        padding: 5%;
    }
}