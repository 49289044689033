.lista-conocimiento{
    padding: 0!important;
    margin-top: 16px;
}
.lista-conocimiento li{
    color: rgb(204, 216, 248);
    list-style: none;
    display: inline-block;
    background-color: rgb(18,43,57);
    padding: 5px 12px;
    border-radius: 20px;
    margin: 3px 1px;
}