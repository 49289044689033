.separar{
    margin-top: 50px!important;
}
.center{
    text-align: center;
    background-color: black;
    padding: 4% 0;
}
.curso{
    padding: 0 20%;
}
.desarrollo .enfasis{
    margin-top: 100px;
}
.desarrollo{
    padding: 0 10%;
}
.desarrollo .row{
    text-align: left;
}
.abajo{
    padding: 0 8%;
    margin: 0!important;
}
.pintar-tercero{
    background-color: rgb(12, 31, 58);
    margin: 0!important;
    padding: 3% 0 4% 0;
}
@media (max-width: 768px) {
    .row>div{
        margin-bottom: 10px;
    }
    .curso{
        padding: 0 5%;
    }
    .desarrollo{
        padding: 0 5%;
    }
    .bajar{
        margin-top: 25px!important;
    }
    .abajo{
        padding: 0 5%;
    }
}
