footer a, footer p{
    color: rgb(139, 146, 174);
}
footer{
    padding: 20px 5%;
}
i{
    margin: 5px;
    font-size: 30px;
    transition: 0.8s;
}
footer i:hover{
    color: white;
    font-size: 25px;
}
footer p{
    margin: 0;
    margin-top: 5px;
}